import React from "react";

function ServiceType1(){
    return (
<section className="services-section pt-100 pb-70">
            <div className="container">
                <div className="section-title"><span>Our Services</span>
                    <h2>Expolre Our Data Services</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-big-data"></i></div>
                            <h3>UI/UX Design</h3>
                            <p>We design and develop innovative digital user experiences. We make sure our tightly written code is optimized to work on multiple device types and browsers.</p>
                            <a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-data-analytics"></i></div>
                            <h3>App Development</h3>
                            <p>Building amazing applications that function seamlessly across any Android device and offer the best mobile user experience. </p>
                            <a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-document"></i></div>
                            <h3>Web Development</h3>
                            <p>We offer a complete range of Website Design and Development, includes Corporate Web Site, CMS, Web Hosting, Internet Marketing, SEO.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-chart"></i></div>
                            <h3>ERP Management</h3>
                            <p>A variety of management services to facilitate ease in work including project and task management, sales and marketing automation.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-science"></i></div>
                            <h3>Digital Marketing</h3>
                            <p>we take immense pride in ourselves for driving traffic, converting visitors, and measuring effectiveness to ultimately deliver tangible results for our clients.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-data-management"></i></div>
                            <h3>Corporate Training &amp; Workshop</h3>
                            <p>To simplify your learning experience we offering you a comprehensive range of IT courses. From certification courses to private one-on-one sessions.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceType1