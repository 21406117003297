import React from "react";
import AboutShort from "../component/about/AboutShort";
import CounterType1 from "../component/counter/CounterType1";
import AboutStart from "../component/about/AboutStart";
import {Link} from 'react-router-dom';

export default function About(){
    const breadcum = {
        backgroundImage:'url("_next/static/images/about_us.jpeg")',
        backgroundSize:'cover',
        height: "350px"
    }
    return (
        <>
        <div className="page-title-area" style={breadcum}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>About Us</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>About</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AboutShort />
        <CounterType1 />
        <AboutStart />
        </>
    );
}