import React from "react";
import {Link} from "react-router-dom";

function AboutStart(){
    return (
<section className="productive-section pt-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="productive-content"><span>Let’s Get Started</span>
                            <h3>Are you ready for a better, more productive business </h3>
                            <p>We transform your business by building innovative digital applications. Get started today, and let us help you harness the power of emerging technologies.</p>
                            <div className="productive-btn">
                                <Link className="productive-btn" to="/project">Get Started
                                    Project<span></span></Link>
                                <Link to="/contact" className="productive-btn-one">Contact
                                    With Us<span></span></Link></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="productive-image"><img
                                src="_next/static/images/productive-7fe50665f9262731e9ae4e41f7f6a4b6.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutStart