import React from "react";
import {Link} from "react-router-dom";

export default function Footer(){
    return (
        <footer className="footer-section ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>About Us</h3>
                            </div>
                            <p>We are trusted by small to mid-sized organizations to advance their technology objectives through custom software solutions including mobile & web application development, digital marketing, Software Security and Quality Assurance (QA Testing) etc.</p>
                            <ul className="footer-social">
                                <li><a href="/#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="/#"><i className="fab fa-pinterest"></i></a></li>
                                <li><a href="/#"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>Important Links</h3>
                            </div>
                            <ul className="footer-quick-links">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/project">Project</Link></li>
                                <li><Link to="/service">Services</Link></li>
                                <li><Link to="/team">Team</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>Featured Service</h3>
                            </div>
                            <ul className="footer-quick-links">
                                <li><a href="/#">ERP Solution</a></li>
                                <li><a href="/#">Ecommerce</a></li>
                                <li><a href="/#">Mobile Application</a></li>
                                <li><a href="/#">Digital marketing</a></li>
                                <li><a href="/#">Corporate Training &amp; Workshop</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>Contact Info</h3>
                            </div>
                            <div className="footer-info-contact"><i className="flaticon-call-answer"></i>
                                <h3>Phone</h3><span>+91-70076 97503</span>
                            </div>
                            <div className="footer-info-contact"><i className="flaticon-envelope"></i>
                                <h3>Email</h3><span><a href="/#" className="__cf_email__">info@cyberoxys.com</a></span>
                            </div>
                            <div className="footer-info-contact"><i className="flaticon-maps-and-flags"></i>
                                <h3>Address</h3><span>Susuwahi Lanka BHU varanasi UP</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className="partner-shape-img1">
                <img src="_next/static/images/partnar-shape-2-9a920e2facd72b86e43ed4119cee4998.png" alt="" />
            </div>
        </footer>
    );
}