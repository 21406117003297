import React from "react";

function SoftwareDevelopmentService1(){
    return (
<div className="pb-100">
            <section className="software-section p-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="software-content"><span>Our Featured Services</span>
                                <h2>Software Developement Services</h2>
                                <p>Software development with effective digital transformation to help you tackle all technical challenges.</p>
                                <ul className="features-list">
                                    <li><span>Software Development</span></li>
                                    <li><span>Automatic Optimization</span></li>
                                    <li><span>Applicatication Services</span></li>
                                    <li><span>IT Security Services</span></li>
                                    <li><span>Data Analytics</span></li>
                                    <li><span>System Integration Service</span></li>
                                    <li><span>Help Desk Services</span></li>
                                    <li><span>API Integration Service</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="software-image"><img
                                    src="_next/static/images/software-71e232d96946abd5fc7b5389a2d09827.png"
                                    alt="" /></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SoftwareDevelopmentService1