import './App.css';
import Nav from './layout/Nav'
import Home from './pages/Home';
import Footer from './layout/Footer';
import Copyright from './layout/Copyright';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Project from './pages/Project';
import About from './pages/About';
import Contact from './pages/Contact';
import Service from './pages/Service';
import Team from './pages/Team';
import Training from './pages/Training';


function App() {
  return (
    <Router>
    <div id="__next">
      <Nav></Nav>
      <Routes>
        <Route exact path='/' element={< Home />}></Route>
        <Route path='/about' element={< About />}></Route>
        <Route path='/service' element={< Service />}></Route>
        <Route path='/training' element={< Training />}></Route>
        <Route path='/project' element={< Project />}></Route>
        <Route path='/team' element={< Team />}></Route>
        <Route path='/contact' element={< Contact />}></Route>
        </Routes>
      <Footer />
      <Copyright />
      {/* <Preloader /> */}
    </div>
      
    </Router>
  );
}

export default App;
