import React from "react";
import SliderType1 from "../component/sliders/SliderType1";
import ServiceType1 from "../component/service/ServiceType1";
import AboutShort from "../component/about/AboutShort";
import CounterType1 from "../component/counter/CounterType1";
import ProcessType1 from "../component/process/ProcessType1";
import SoftwareDevelopmentService1 from "../component/service/SoftwareDevelopmentService1";
import Testimonial from "../component/testimonial/Testimonial";
import AboutStart from "../component/about/AboutStart";
import BlogGrid3 from "../component/blogs/list/BlogGrid3";
import Brand from "../component/clients/Brand";


function Home(){
    return (
           <div>
               <SliderType1 />
               <ServiceType1 />
               <AboutShort />
               <CounterType1 />
               <ProcessType1 />
               <SoftwareDevelopmentService1 />
               <Testimonial />
               <AboutStart />
               {/* <BlogGrid3 /> */}
               {/* <Brand /> */}
           </div>
    );
}
export default Home