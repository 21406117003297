import React from "react";
import {Link} from "react-router-dom";

export default function Team(){
    const breadcum = {
        backgroundImage:'url("_next/static/images/team_banner.jpeg")',
        backgroundSize:'cover',
        height: "350px",
    }
    const teams = [
        {
            name: 'Happy',
            profile_img : 'male.png', 
            designation: 'CTO'
        },
        {
            name: 'Rahul Kumar',
            profile_img : 'rahul.jpeg', 
            designation: 'Sr. Android Developer'
        },
        {
            name: 'Mayank Kumar',
            profile_img : 'male.png', 
            designation: 'Sr. PHP Developer'
        },
        {
            name: 'Sunidhi Tiwari',
            profile_img : 'female.jpeg', 
            designation: 'Sr. UX/UI Designer'
        },
        {
            name: 'Mithilesh Kumar',
            profile_img : 'mithlesh.jpeg', 
            designation: 'Marketing Manager'
        },
        {
            name: 'Ranjeet Kumar',
            profile_img : 'ranjeet.jpeg', 
            designation: 'Frelancer'
        },
        // {
        //     name: 'Arvind Pratap Singh',
        //     profile_img : 'arvind_pratap_singh.jpeg', 
        //     designation: 'Marketing Head'
        // },
        {
            name: 'Jyoti Singh',
            profile_img : 'female.jpeg', 
            designation: 'Laravel'
        },
        {
            name: 'Chhaya Kumari',
            profile_img : 'female.jpeg', 
            designation: 'Manager'
        },
        
        {
            name: 'Anil Kumar Rai',
            profile_img : 'male.png', 
            designation: 'React Developer'
        },
        {
            name: 'Navneet Kumar',
            profile_img : 'navneet.jpeg', 
            designation: 'PHP Trainner'
        },
        {
            name: 'Ajay Yadav',
            profile_img : 'ajay.jpeg', 
            designation: 'Project manager'
        },
        
        
    ]
    return (
        <>
        <div class="page-title-area" style={breadcum}>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            {/* <h2>Team</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Our Team Member</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="team-section pt-100 pb-70">
            <div class="container">
                <div class="section-title"><span>Our Experts</span>
                    <h2>Meet Our Leadership Preparing For Your Success</h2>
                </div>
                <div class="row">
                    {teams.map((team)=>{
                        return (
                            <div class="col-lg-3 col-sm-6">
                        <div class="single-team-item">
                            <div class="team-image">
                                <img src={"_next/static/images/team/"+team.profile_img} alt="team" />
                                {/* <div class="team-social">
                                    <Link target="_blank" to="#"> <i class="fab fa-facebook-f"></i></Link>
                                    <Link target="_blank" to="#"><i class="fab fa-twitter"></i></Link>
                                    <Link target="_blank" to="#"><i class="fab fa-instagram"></i></Link>
                                </div> */}
                            </div>
                            <div class="team-content">
                                <h3>{team.name}</h3><span>{team.designation}</span>
                            </div>
                        </div>
                    </div>
                        );
                    })}
                </div>
            </div>
        </section>
        </>
    );
}