import React from "react";
import SoftwareDevelopmentService1 from "../component/service/SoftwareDevelopmentService1";
import ServiceType1 from "../component/service/ServiceType1";

export default function Service(){
    const breadcum = {
        backgroundImage:'url("_next/static/images/service_banner.jpeg")',
        backgroundSize:'cover',
        height: "350px",
        marginBottom: "50px"
    }
    return (
        <>
        <div className="page-title-area" style={breadcum}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            {/* <h2>Service</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Service</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SoftwareDevelopmentService1 />
        <ServiceType1 />
        </>
    );
}