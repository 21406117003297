import React from "react";
import {Link} from "react-router-dom";

export default function Contact(){
    const breadcum = {
        backgroundImage:'url("_next/static/images/contact_us_banner.jpeg")',
        backgroundSize:'cover',
        height: "350px",
    }
    return (
        <>
        <div class="page-title-area" style={breadcum}>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="page-title-content">
                            <h2>Contact Us</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-box pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-box"><i class="fa fa-map-marker"></i>
                            <div class="content-title">
                                <h3>Address</h3>
                                <p>Susuwahi Lanka BHU varanasi UP</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-box"><i class="fa fa-envelope"></i>
                            <div class="content-title">
                                <h3>Email</h3>
                                
                                <p><a href="/cdn-cgi/l/email-protection" class="__cf_email__">info@cyberoxys.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="single-contact-box"><i class="fa fa-phone"></i>
                            <div class="content-title">
                                <h3>Phone</h3>
                                <p>+91-70076 97503</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-section ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="contact-image"><img
                                src="/_next/static/images/contact-0b960cefb2fe8c05cb17a6bd19cdf1a0.png" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group"><input type="text" id="name" class="form-control"
                                                placeholder="Name" required="" /></div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group"><input type="email" id="email" class="form-control"
                                                placeholder="Email" required="" /></div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group"><input type="text" id="phone_number"
                                                class="form-control" placeholder="Phone" required="" /></div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group"><input type="text" id="subject" class="form-control"
                                                placeholder="Subject" required="" /></div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group"><textarea id="message" class="form-control" rows="6"
                                                placeholder="Your Message"></textarea></div>
                                    </div>
                                    <div class="col-lg-12 col-md-12"><button type="submit" class="submit-btn mt-2">Send
                                            Message!</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}