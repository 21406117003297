import React from "react";

function Training(){
    return (
<section className="services-section pt-100 pb-70">
            <div className="container">
                <div className="section-title"><span>Our Courses</span>
                    <h2>We help to be an expert</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-big-data"></i></div>
                            <h3>Website Designing</h3>
                            <p></p>
                            <a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-document"></i></div>
                            <h3>Website Development</h3>
                            <p>We offer a complete range of Website Design and Development, includes Corporate Web Site, CMS, Web Hosting, Internet Marketing, SEO.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-data-analytics"></i></div>
                            <h3>App Development</h3>
                            <p>Building amazing applications that function seamlessly across any Android device and offer the best mobile user experience. </p>
                            <a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-science"></i></div>
                            <h3>Digital Marketing</h3>
                            <p>we take immense pride in ourselves for driving traffic, converting visitors, and measuring effectiveness to ultimately deliver tangible results for our clients.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services-box">
                            <div className="icon"><i className="flaticon-data-management"></i></div>
                            <h3>Graphic Designing</h3>
                            <p>To simplify your learning experience we offering you a comprehensive range of IT courses. From certification courses to private one-on-one sessions.</p><a className="service-btn"
                                href="/#">Read More<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Training