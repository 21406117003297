import React from "react";
import {Link } from 'react-router-dom';

function Nav(){
    return (
        <div id="navbar" className="navbar-area">
            <div className="main-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand" to="/">
                        <h2 style={{color:"#fff"}}>Cyberoxys</h2>
                        </Link>
                        <button
                            className="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation"><span
                                className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                
                                <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/about">About us</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/service">Services</Link></li>
                                {/* <li className="nav-item"><Link className="nav-link" to="/training">Training</Link></li> */}
                                <li className="nav-item"><Link className="nav-link" to="/project">Projects</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/team">Team</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
                                {/* <li className="nav-item"><Link className="nav-link" to="/#">Services <i
                                            className="fas fa-chevron-down"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="nav-link" to="services.html">Services</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="service-details.html">Service
                                                Details</Link></li>
                                    </ul>
                                </li> */}
                                
                                {/* <li className="nav-item"><Link className="nav-link" to="/#">Projects <i
                                            className="fas fa-chevron-down"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="nav-link" to="projects.html">Projects</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="project-details.html">Project
                                                Details</Link></li>
                                    </ul>
                                </li> */}
                                {/* <li className="nav-item"><Link className="nav-link" to="/#">Pages <i
                                            className="fas fa-chevron-down"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="nav-link" to="about-us.html">About Us</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="team.html">Team</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="pricing.html">Pricing</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="partner.html">Partner</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="faq.html">FAQ</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="error.html">404 error</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="coming-soon.html">Coming Soon</Link>
                                        </li>
                                    </ul>
                                </li> */}
                                {/* <li className="nav-item"><Link className="nav-link" to="/#">Blog <i
                                            className="fas fa-chevron-down"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="nav-link" to="blog.html">Blog Grid</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="blog2.html">Blog Right
                                                Sidebar</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="blog3.html">Blog Left Sidebar</Link>
                                        </li>
                                        <li className="nav-item"><Link className="nav-link" to="blog-details.html">Blog
                                                Details</Link></li>
                                    </ul>
                                </li> */}
                                
                            </ul>
                            {/* <div className="others-options"><Link className="default-btn" to="pricing.html">Free
                                    Quote<span></span></Link></div> */}
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}
export default Nav