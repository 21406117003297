import React from "react";

function Testimonial(){
    return (
<section className="testimonial-section ptb-100">
            <div className="container">
                <div className="section-title"><span>Testimonial</span>
                    <h2>What Our Client Say</h2>
                </div>
            </div>
            <div className="testimonial-shape"><img
                    src="_next/static/images/testimonial-shape-1-1969c6d7185eef98749b2b42ea5dd5e7.png"
                    alt="main" /></div>
            <div className="testimonial-shape-img1"><img
                    src="_next/static/images/testimonial-shape-2-b8d3e0f842a772371ad3445c977a3cf6.png"
                    alt="main" /></div>
            <div className="testimonial-shape-img2"><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAA2ElEQVR42pzTvQsBcRjA8Z+XkpEyyCwpi2ST1WCQxcjuZVH8C5KZkrLIYLUZZJDRZDScySSjlyS+j+7qSHL31Oe6q/ve+zna2kWZxoUycojAgwM2GGJm3tltWo9jgIR6nwCiKKCHymccwhw+9Xvkqh6oyoYTXvT/CI2p6Lf1ikvIKmtTl5NKnFfWJ4WwxEEbsXQxWdyUvblKrNkIT/LuJR7biBfYSjxF12Lckvct8R0NrP8Mm1gZT03mjDRGP6Ijauh8+7blIRT1HyCDJPzYYYkJ9uajPQUYADHWJfG5LbavAAAAAElFTkSuQmCC"
                    alt="main" /></div>
        </section>
    );
}

export default Testimonial