import React from "react";

function AboutShort(){
    return (
<section className="about-section pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image"><img
                                src="_next/static/images/about-800ee6a1d98aa15666a2867a7bb26ddd.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content"><span>About Us</span>
                            <h2>Focused On Actionable Insights</h2>
                            <p>we’re the experts in designing and developing technology products that help your business streamline processes and grow its revenue. We build custom software applications that are tailored to meet your specific use case, budget, and timeline.</p>
                            <ul className="about-list">
                                <li><i className="flaticon-tick"></i>Professional Consultancy Service</li>
                                <li><i className="flaticon-tick"></i>24/7 Support Center</li>
                                <li><i className="flaticon-tick"></i>Custom Service &amp; Operation</li>
                                <li><i className="flaticon-tick"></i>Advanced Advisory Team</li>
                            </ul>
                            {/* <Link className="default-btn" to="">Read More <span></span></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutShort