import React from "react";
import {Link} from "react-router-dom";

export default function Project(){
    const breadcum = {
        backgroundImage:'url("_next/static/images/project_banner.webp")',
        backgroundSize:'cover',
        height: "350px",
    }
    return (
        <>
        <div className="page-title-area" style={breadcum}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Projects</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Projects</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="project-section pt-100 pb-70">
            <div class="container">
                <div class="section-title"><span>Project</span>
                    <h2>Our Latest Project</h2>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image">
                                <img src="/_next/static/images/projects/fcsl.png" alt="" />
                            </div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/#">FCSL</a></h3> */}
                                    {/* <p>Data Analysis</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image"><img
                                    src="/_next/static/images/projects/medicalexpo.png"
                                    alt="" /></div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/project-details">Data is the new science big data holds the answer</a>
                                    </h3>
                                    <p>Business Intelligence</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image"><img
                                    src="/_next/static/images/projects/nandigram_aarti.png"
                                    alt="" /></div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/project-details">Data is the new science big data holds the answer</a>
                                    </h3>
                                    <p>Data Management</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image"><img
                                    src="/_next/static/images/projects/natural_ecom.png"
                                    alt="" /></div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/project-details">Data is the new science big data holds the answer</a>
                                    </h3>
                                    <p>Data Analysis</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image"><img
                                    src="/_next/static/images/projects/sugandha_natural.png"
                                    alt="" /></div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/project-details">Data is the new science big data holds the answer</a>
                                    </h3>
                                    <p>Business Intelligence</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-project">
                            <div class="project-image"><img
                                    src="/_next/static/images/projects/ubd2.png"
                                    alt="" /></div>
                            <div class="image-hover">
                                <div class="text">
                                    {/* <h3><a href="/project-details">Data is the new science big data holds the answer</a>
                                    </h3>
                                    <p>Data Management</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}