import React from "react";

function ProcessType1(){
    return (
<section className="process-section pt-100 pb-70">
            <div className="container">
                <div className="section-title"><span>Our Working Process</span>
                    <h2>Software Development Process</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="process-item"><img
                                src="_next/static/images/process1-81cf078a0a67480dd317eac93682e082.png" alt="" />
                            <h3>Planning &amp; Design</h3>
                            <p>We start our project with proper planning and divide the project into smaller component and work accordingly.</p>
                            {/* <Link className="process-btn">Read More<i
                                    className="flaticon-right"></i></Link> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process-item"><img
                                src="_next/static/images/process2-670f2a00d3df7db7a5a137053da1f4dc.png" alt="" />
                            <h3>Development &amp; Testing</h3>
                            <p>After planning and data collection we move to development phase where our development team will perform needfull task</p>
                            {/* <a className="process-btn" href="project-details.html">Read More<i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="process-item"><img
                                src="_next/static/images/process3-ce3133629c5dcb7a5cee56c19872a072.png" alt="" />
                            <h3>Complete &amp; Support</h3>
                            <p>After successfull testing of project, we go for live and deploy on the server. We also provide support to our customer when needed.</p>
                                {/* <a className="process-btn" href="project-details.html">Read More<i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProcessType1