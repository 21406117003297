import React from "react";
import {Link} from "react-router-dom";

function SliderType1(){
    return (
        <div className="main-banner-two">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="main-banner-content">
                                <h1>Build something amazing</h1>
                                <p>From Astonishing Product Design To Flawless Code, We Help You To Dominate !</p>
                                <div className="banner-btn">
                                    <Link className="default-btn-one" to="/about">About
                                        Us<span></span></Link>
                                    <Link className="default-btn" to="/contact">Contact
                                        Us<span></span></Link></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-image">
                                <img src="_next/static/images/home-two-shape1-d94985b208cbedc7d3b9dce7093e938b.png" alt="" className="fadeInUp" />
                                <img src="_next/static/images/home-two-shape2-94a9f7c45629f0b9067e5f52272eb5a7.png" alt="" className="fadeInLeft" />
                                <img src="_next/static/images/home-two-shape3-04a4ed7f449064412de5a4936a60cde1.png" alt="" className="fadeInRight" />
                                <img src="_next/static/images/home-two-shape4-4568b30f917b7ff5c343cd01e8ccd3ad.png" alt="" className="fadeInUp" />
                                <img src="_next/static/images/home-two-shape5-072857e055cd1973e2a4267721b85d83.png" alt="" className="zoomIn" />
                                <img src="_next/static/images/main-image2-24af97bedc5794136704cfd2c576028b.png" alt="" className="zoomIn" />
                            </div>
                            <div className="circle-img">
                                <img src="_next/static/images/home-two-shape6-9333f3cd0eb4010a36957ac8c6f87cf7.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="creative-shape"><img src="_next/static/images/home-shape-2-45ee2697b879b410f36107f5fc7773ee.png"
                alt="main-" /></div>
        <div className="shape-dot"><img src="_next/static/images/main-banner-dot-61404125f1f3ae6cf9de3cb70ba8e8f6.png"
                alt="main-" /></div>
    </div>
    );
}

export default SliderType1