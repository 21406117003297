import React from "react";

export default function Copyright(){
    return (
        <div className="copyright-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <p>All Rights Reserved by<a href="/#"
                                target="_blank">Cyberoxys</a>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ul>
                            <li><a href="/#">Terms &amp; Conditions</a></li>
                            <li><a href="/#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}